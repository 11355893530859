<template>
  <v-container>
    <div v-html="help" style="text-align: left"></div>
  </v-container>
</template>

<script>
export default {
  name: "Help",
  data() {
    return {
      help: null
    }
  },
  mounted() {
    window.axios
        .get('api/help')
        .then((res) => {
          this.help = res.data.data
        })

  }
}
</script>

<style scoped>

</style>